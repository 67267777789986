<template>
<Dropdown
    v-model="columna"
    :options="columnas"
    optionLabel="nombre"
    optionValue="id"
    class="w-full"
    @change="mover"
/>

</template>

<script>
import { sendRequestList } from '@/helper';


const url = process.env.VUE_APP_BASE_URL;

export default {
    props: {
        card: {
            type: Object,
            required: true
        },
        tablero_id: {
            type: String,
            required: true
        },        
    },
    emits: ['datos'],
    data(){
        return{
            columnas: [],
            columna: 0
        }
    },
    methods: {
        async getColumnas() {
            if (this.card.id) {
                const tablero_id = this.tablero_id;
                this.loading = true;
                const result = await sendRequestList(url+"/columnaslist?tablero_id="+tablero_id);
                this.columnas = result.data;
                this.loading = false;
            }
        },
        mover() {
            this.$emit('datos', { columna: this.columna });
        }
    },
    mounted() {
        this.getColumnas();
        this.columna = this.card.columna_id;
    }
};
</script>