<template>
	<div class="layout-footer">
		<img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
		<span class="font-medium ml-2">Desarrollado por INV Agente de Seguros y de Fianzas SA de CV. © Todos los derechos reservados</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'layout/images/logo-white.png' : 'layout/images/logo-dark.png';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>